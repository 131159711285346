import styled from "styled-components"
import { Link } from "gatsby"
import { Card, Col2 } from "./containers"

export const SLink = styled(Link)`
  text-decoration: none;
  ${p => p.theme.layout.tablet} {
    margin-bottom: 16px;
  }
`

export const Col2MaxWidth = styled(Col2)`
  max-width: 1000px;
  margin: 0 auto 2rem;
`

export const SCard = styled(Card)`
  width: 100%;
  padding: 2rem;
  min-height: 200px;
  text-align: center;
  margin: 0;

  ${p => p.theme.layout.tablet} {
    min-height: 400px;
  }

  h2 {
    border: none;
  }
`

export const SNavLogo = styled.div`
  width: 100%;
  max-width: 250px;
  margin: 1rem auto 3rem;
`
