import React from "react"
import Layout from "../components/layout"
import { SCard } from "../style/initial"

const FormComplete = () => {
  return (
    <Layout emailNoCheck={true}>
      <SCard
        style={{ flexDirection: "column", maxWidth: "600px", margin: "0 auto" }}
      >
        <h1>Form submitted</h1>
        <p>Thank you, that's everything.</p>
        <p>
          We have received your submission and will need some time to process
          your application
        </p>
      </SCard>
    </Layout>
  )
}

export default FormComplete
